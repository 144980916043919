import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TopHeadIcon } from "../SVG/SVGs";
import { PiShoppingCartThin } from "react-icons/pi";
// import data from "../data.json";
import { DefaultButton } from "../Actions";
import { Link } from "react-router-dom";

const SHEET_ID = "1Ba9zv29Q_cxGlSdcSs2IppD8WV9EY8qgeXfU93fUwl8";
const API_KEY = "AIzaSyD7UeLzw3xFjyixuuIvZbafRxJzU9D5U3c";
const SHEET_NAME = "Sheet1";

const fetchData = async () => {
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_NAME}?key=${API_KEY}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (!data.values) {
      console.error("Error: No values returned from API", data);
      return [];
    }

    return data.values;
  } catch (error) {
    console.error("Fetch Error:", error);
    return [];
  }
};

const Product = ({ addToCart }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData().then((rows) => {
      console.log("Fetched Data:", rows);
      setData(rows.slice(1)); // Skip header row
    });
  }, []);

  const convertDriveLink = (url) => {
    const match = url.match(/\/d\/([^/]+)\//);
    return match ? `https://lh3.googleusercontent.com/d/${match[1]}=s220` : url;
  };

  return (
    <Container id="product">
      <Wrapper>
        <Head>
          <TopHeadIcon />
          <span>Our Products</span>
        </Head>
        <SubHead>Explore Our Premium Products</SubHead>
        <Products>
          {data.slice(0, 4).map((row, index) => (
            <ProductCard key={index}>
              <ImageHolder>
                <Image src={convertDriveLink(row[1])} alt={row[2]} />
              </ImageHolder>
              <Bottom className="product_card" onClick={() => addToCart(row)}>
                <Icons>
                  <span>Add to cart </span>{" "}
                  <Order>
                    <PiShoppingCartThin />
                  </Order>
                </Icons>
                <Name>{row[2]}</Name>
                <Price>{row[3]}</Price>
              </Bottom>
            </ProductCard>
          ))}
        </Products>
        <Linker>
          <Link
            to="/productPage"
            style={{
              textDecoration: "none",
            }}
          >
            <DefaultButton text="See More" />
          </Link>
        </Linker>
      </Wrapper>
    </Container>
  );
};

export default Product;

const Container = styled.div`
  width: 100%;
  background: #f9f9f9;
  padding-bottom: 60px;
`;

const Wrapper = styled.div`
  margin: 0 50px;
  padding-top: 120px;
  @media screen and (max-width: 320px) {
    margin: 0;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 16px;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 500;
    color: #23769e;
    font-family: "Exo", serif;
  }
  @media screen and (max-width: 425px) {
    justify-content: center;
  }
`;

const SubHead = styled.h2`
  font-family: "Exo", serif;
  margin: 0;
  font-size: 40px;
  @media screen and (max-width: 425px) {
    font-size: 30px;
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    font-size: 25px;
  }
`;

const Products = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
  }
`;

const ProductCard = styled.div`
  width: 270px;
  height: 320px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 1024px) {
    margin: 20px;
  }
`;

const ImageHolder = styled.div`
  background-image: url("/assets/asset20.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 170px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.27);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: 1;
`;

const Name = styled.div`
  font-family: "Exo", serif;
  max-width: 80%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  opacity: 0.7;
`;

const Price = styled.div`
  margin-top: 18px;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.7;
`;

const Bottom = styled.div`
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
`;

const Icons = styled.div`
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  /* color: #7c0029; */
  color: #23769e;

  transition: all 500ms;
  span {
    margin-right: 10px;
  }
  &:hover {
    opacity: 1;
  }
`;

const Order = styled.div`
  line-height: 0;
  cursor: pointer;
  a {
    color: unset;
  }
`;

const Linker = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  text-decoration: none;
`;

// const Container = styled.div``

// const Container = styled.div``

// const Container = styled.div``

// const Container = styled.div``
