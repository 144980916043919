import React from "react";
import styled from "styled-components";

const Topper = styled.svg`
  @media screen and (max-width: 320px) {
    width: 60px;
  }
`;

export function TopHeadIcon() {
  return (
    <div>
      <Topper
        width="90"
        height="46"
        viewBox="0 0 361 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 23L338 23"
          stroke="#23769E"
          stroke-width="2"
          stroke-linecap="round"
        />
        <circle cx="338" cy="23" r="23" fill="#23769E" />
      </Topper>
    </div>
  );
}

export function Rotator() {
  return (
    <div>
      <svg
        width="130"
        height="130"
        viewBox="0 0 185 185"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M95.6584 18.2638C95.2838 18.2475 94.9683 18.0994 94.7117 17.8195C94.4551 17.5396 94.3349 17.2123 94.3512 16.8378C94.3675 16.4632 94.5156 16.1477 94.7955 15.8911C95.0754 15.6345 95.4027 15.5143 95.7772 15.5306C96.1518 15.5469 96.4674 15.695 96.724 15.9749C96.9805 16.2548 97.1007 16.5821 97.0844 16.9566C97.0736 17.2046 97.0005 17.4297 96.8649 17.6317C96.7344 17.8339 96.5626 17.9938 96.3496 18.1113C96.1419 18.224 95.9115 18.2748 95.6584 18.2638Z"
          fill="#2B2B2B"
        />
        <path
          d="M108.163 19.5627L106.262 19.024L116.004 5.60844L117.876 6.13894L119.13 22.6711L117.229 22.1323L116.327 8.25918L116.21 8.22602L108.163 19.5627ZM110.523 13.9125L118.185 16.0842L117.729 17.6927L110.067 15.521L110.523 13.9125Z"
          fill="#2B2B2B"
        />
        <path
          d="M122.265 24.1042L129.367 10.2555L130.963 11.0739L128.341 16.186L128.476 16.2554C128.686 16.1351 128.966 15.9885 129.317 15.8153C129.675 15.6399 130.106 15.5424 130.612 15.5226C131.124 15.5007 131.718 15.6631 132.394 16.0098C133.269 16.4583 133.928 17.0723 134.371 17.8517C134.814 18.6312 135.016 19.5181 134.979 20.5124C134.942 21.5067 134.643 22.5493 134.084 23.6403C133.52 24.7402 132.844 25.5978 132.056 26.2128C131.27 26.8234 130.433 27.1796 129.543 27.2816C128.656 27.379 127.78 27.2058 126.915 26.7619C126.247 26.4198 125.77 26.0354 125.482 25.6087C125.196 25.1776 125.018 24.7645 124.947 24.3695C124.879 23.97 124.835 23.6456 124.815 23.3961L124.625 23.299L123.807 24.8949L122.265 24.1042ZM126.497 19.7155C126.095 20.4999 125.855 21.2508 125.777 21.9683C125.702 22.6813 125.799 23.3201 126.067 23.8847C126.338 24.4449 126.793 24.8891 127.433 25.2174C128.101 25.5596 128.747 25.6692 129.374 25.5465C130.008 25.4215 130.591 25.1171 131.124 24.6331C131.663 24.1469 132.123 23.5342 132.502 22.7948C132.876 22.0645 133.101 21.3486 133.176 20.6469C133.258 19.9431 133.162 19.3043 132.887 18.7305C132.618 18.1545 132.146 17.6931 131.47 17.3463C130.821 17.0134 130.191 16.8982 129.58 17.0007C128.972 17.0988 128.405 17.383 127.879 17.8535C127.355 18.3194 126.895 18.9401 126.497 19.7155Z"
          fill="#2B2B2B"
        />
        <path
          d="M137.712 33.6816C136.878 33.0384 136.298 32.2754 135.975 31.3926C135.655 30.5129 135.593 29.5798 135.791 28.5933C135.992 27.61 136.461 26.6408 137.197 25.6857C137.939 24.7225 138.761 24.0163 139.664 23.567C140.571 23.1208 141.49 22.9403 142.422 23.0254C143.358 23.1137 144.244 23.4794 145.079 24.1226C145.913 24.7658 146.491 25.5273 146.81 26.407C147.134 27.2898 147.194 28.2249 146.99 29.2122C146.789 30.2027 146.318 31.1795 145.576 32.1426C144.84 33.0977 144.02 33.7964 143.116 34.2385C142.217 34.6838 141.299 34.8623 140.363 34.7741C139.431 34.689 138.547 34.3248 137.712 33.6816ZM138.696 32.4055C139.33 32.8941 139.977 33.1335 140.637 33.1239C141.296 33.1143 141.929 32.9204 142.534 32.5422C143.138 32.164 143.679 31.6659 144.155 31.0479C144.631 30.4299 144.977 29.7783 145.192 29.093C145.406 28.4078 145.436 27.7429 145.28 27.0984C145.124 26.4539 144.729 25.8873 144.095 25.3987C143.461 24.9101 142.813 24.6727 142.15 24.6863C141.487 24.6999 140.851 24.8979 140.243 25.2801C139.636 25.6623 139.093 26.1624 138.617 26.7804C138.141 27.3984 137.797 28.048 137.586 28.7292C137.374 29.4105 137.348 30.0714 137.506 30.7118C137.665 31.3523 138.062 31.9169 138.696 32.4055Z"
          fill="#2B2B2B"
        />
        <path
          d="M153.022 41.3454L158.138 36.7151L159.342 38.0448L150.687 45.8775L149.484 44.5478L150.949 43.222L150.867 43.1318C150.244 43.3267 149.585 43.3495 148.889 43.2C148.197 43.0472 147.556 42.6439 146.964 41.9903C146.475 41.4494 146.158 40.8615 146.014 40.2267C145.873 39.5884 145.935 38.9315 146.198 38.2559C146.465 37.5769 146.967 36.9043 147.703 36.238L153.202 31.2609L154.405 32.5907L148.996 37.4861C148.365 38.0573 148.022 38.6894 147.966 39.3824C147.913 40.0792 148.137 40.7037 148.636 41.2559C148.935 41.5864 149.324 41.8461 149.803 42.035C150.284 42.2276 150.805 42.2822 151.366 42.199C151.929 42.1194 152.481 41.8349 153.022 41.3454Z"
          fill="#2B2B2B"
        />
        <path
          d="M165.526 46.7636L164.27 47.6186L160.867 42.6172L162.123 41.7623L165.526 46.7636ZM165.427 41.6468L166.436 43.1296L157.238 49.388C156.819 49.673 156.546 49.9475 156.419 50.2114C156.3 50.4767 156.27 50.7299 156.329 50.9711C156.396 51.2135 156.504 51.4437 156.652 51.6615C156.763 51.8249 156.863 51.9532 156.951 52.0465C157.043 52.137 157.117 52.2094 157.172 52.2636L156.045 53.4715C155.939 53.3966 155.806 53.2819 155.646 53.1275C155.481 52.9759 155.305 52.7618 155.117 52.4854C154.832 52.0665 154.643 51.5947 154.549 51.0701C154.459 50.5496 154.508 50.032 154.697 49.5173C154.889 49.0068 155.266 48.5607 155.827 48.1788L165.427 41.6468Z"
          fill="#2B2B2B"
        />
        <path
          d="M172.137 70.9361C170.594 71.4946 169.159 71.6987 167.833 71.5481C166.507 71.3976 165.363 70.9468 164.402 70.1956C163.44 69.4444 162.734 68.4448 162.282 67.1966C161.83 65.9485 161.734 64.7281 161.992 63.5356C162.25 62.3431 162.84 61.2647 163.763 60.3005C164.685 59.3362 165.918 58.5748 167.462 58.0163C169.005 57.4577 170.44 57.2537 171.766 57.4042C173.092 57.5547 174.236 58.0055 175.197 58.7567C176.159 59.5079 176.865 60.5075 177.317 61.7557C177.769 63.0038 177.866 64.2242 177.607 65.4167C177.349 66.6092 176.759 67.6876 175.836 68.6518C174.914 69.6161 173.681 70.3775 172.137 70.9361ZM171.517 69.221C172.784 68.7625 173.777 68.1634 174.495 67.4239C175.215 66.6892 175.669 65.8812 175.856 64.9998C176.046 64.1231 175.98 63.2417 175.659 62.3556C175.339 61.4696 174.824 60.7479 174.116 60.1906C173.41 59.638 172.544 59.3075 171.519 59.199C170.495 59.0953 169.35 59.2727 168.082 59.7313C166.815 60.1899 165.822 60.7865 165.102 61.5212C164.383 62.2607 163.929 63.0688 163.74 63.9454C163.553 64.8268 163.619 65.7106 163.94 66.5967C164.261 67.4828 164.774 68.2021 165.481 68.7546C166.189 69.3119 167.054 69.6424 168.078 69.7462C169.103 69.8547 170.249 69.6796 171.517 69.221Z"
          fill="#2B2B2B"
        />
        <path
          d="M171.268 83.856L178.099 82.882L178.352 84.6575L166.796 86.3051L166.543 84.5296L168.499 84.2507L168.482 84.1303C167.856 83.9432 167.297 83.593 166.805 83.0799C166.317 82.566 166.011 81.8727 165.886 81C165.783 80.2778 165.85 79.6133 166.086 79.0065C166.327 78.399 166.745 77.8889 167.341 77.4764C167.943 77.0632 168.735 76.7865 169.718 76.6463L177.061 75.5994L177.314 77.3749L170.091 78.4047C169.249 78.5248 168.61 78.8563 168.176 79.3993C167.742 79.9473 167.578 80.5899 167.683 81.3272C167.746 81.7686 167.923 82.2014 168.214 82.6256C168.505 83.0549 168.906 83.3918 169.417 83.6362C169.929 83.8857 170.545 83.9589 171.268 83.856Z"
          fill="#2B2B2B"
        />
        <path
          d="M166.924 90.3948L178.596 90.4424L178.589 92.175L176.826 92.1678L176.826 92.2894C177.402 92.5046 177.869 92.8915 178.227 93.4502C178.584 94.009 178.762 94.6379 178.759 95.3371C178.758 95.4688 178.755 95.6335 178.749 95.831C178.743 96.0286 178.735 96.178 178.724 96.2793L176.901 96.2719C176.916 96.2111 176.939 96.0719 176.971 95.8542C177.007 95.6415 177.026 95.4162 177.027 95.178C177.029 94.6106 176.912 94.1035 176.676 93.6567C176.444 93.215 176.122 92.8641 175.707 92.604C175.298 92.3491 174.83 92.2205 174.303 92.2183L166.916 92.1883L166.924 90.3948Z"
          fill="#2B2B2B"
        />
        <path
          d="M173.177 120.451L173.654 118.628C174.207 118.657 174.717 118.589 175.185 118.424C175.651 118.263 176.069 118.027 176.438 117.715C176.812 117.41 177.127 117.044 177.385 116.62C177.643 116.195 177.837 115.732 177.968 115.233C178.207 114.321 178.193 113.435 177.926 112.574C177.657 111.718 177.132 110.955 176.349 110.284C175.565 109.618 174.521 109.114 173.218 108.773C171.914 108.432 170.757 108.359 169.747 108.556C168.736 108.757 167.904 109.165 167.251 109.78C166.597 110.399 166.15 111.165 165.911 112.076C165.781 112.576 165.723 113.075 165.74 113.571C165.757 114.068 165.85 114.54 166.021 114.988C166.196 115.442 166.447 115.854 166.774 116.222C167.106 116.597 167.517 116.906 168.008 117.15L167.531 118.973C166.797 118.635 166.174 118.204 165.661 117.683C165.149 117.161 164.75 116.575 164.464 115.924C164.184 115.274 164.021 114.585 163.977 113.856C163.931 113.132 164.007 112.39 164.206 111.63C164.542 110.346 165.155 109.286 166.044 108.45C166.933 107.615 168.031 107.062 169.337 106.791C170.644 106.52 172.092 106.593 173.68 107.008C175.267 107.424 176.565 108.07 177.571 108.946C178.578 109.823 179.264 110.843 179.63 112.007C179.995 113.171 180.01 114.395 179.674 115.679C179.475 116.439 179.178 117.123 178.783 117.732C178.387 118.345 177.905 118.866 177.337 119.294C176.775 119.722 176.142 120.039 175.439 120.242C174.741 120.447 173.987 120.517 173.177 120.451Z"
          fill="#2B2B2B"
        />
        <path
          d="M159.08 125.198C159.546 124.252 160.18 123.534 160.981 123.042C161.781 122.555 162.683 122.311 163.689 122.31C164.693 122.314 165.736 122.582 166.817 123.115C167.908 123.653 168.762 124.32 169.381 125.116C169.997 125.917 170.355 126.783 170.455 127.714C170.554 128.649 170.37 129.589 169.904 130.534C169.438 131.479 168.805 132.196 168.005 132.683C167.204 133.174 166.299 133.417 165.291 133.411C164.28 133.41 163.23 133.141 162.139 132.603C161.058 132.07 160.211 131.404 159.599 130.605C158.985 129.811 158.629 128.947 158.531 128.011C158.431 127.081 158.614 126.143 159.08 125.198ZM160.525 125.91C160.171 126.628 160.064 127.309 160.203 127.954C160.343 128.599 160.657 129.181 161.147 129.699C161.637 130.218 162.232 130.65 162.932 130.995C163.632 131.34 164.339 131.55 165.053 131.625C165.767 131.7 166.425 131.598 167.026 131.318C167.627 131.039 168.105 130.54 168.459 129.822C168.813 129.104 168.918 128.421 168.773 127.774C168.629 127.127 168.31 126.543 167.815 126.022C167.321 125.502 166.724 125.069 166.024 124.724C165.324 124.379 164.619 124.17 163.91 124.097C163.2 124.024 162.547 124.128 161.951 124.41C161.354 124.692 160.879 125.192 160.525 125.91Z"
          fill="#2B2B2B"
        />
        <path
          d="M155.235 133.248L164.308 140.592L163.217 141.939L161.8 140.791L161.704 140.91C162.036 141.491 162.165 142.1 162.091 142.738C162.022 143.38 161.748 143.996 161.27 144.586C160.785 145.185 160.246 145.573 159.653 145.751C159.06 145.936 158.43 145.944 157.764 145.777L157.688 145.871C157.968 146.483 158.058 147.132 157.958 147.82C157.861 148.511 157.55 149.182 157.024 149.831C156.367 150.642 155.576 151.1 154.65 151.205C153.729 151.313 152.734 150.935 151.667 150.071L145.595 145.155L146.724 143.761L152.796 148.677C153.465 149.219 154.092 149.423 154.676 149.29C155.26 149.156 155.752 148.841 156.154 148.345C156.671 147.707 156.878 147.057 156.775 146.394C156.677 145.734 156.335 145.167 155.748 144.692L149.842 139.91L150.989 138.493L157.203 143.523C157.719 143.941 158.27 144.11 158.856 144.03C159.445 143.953 159.954 143.651 160.381 143.124C160.674 142.761 160.852 142.345 160.914 141.873C160.973 141.406 160.909 140.937 160.723 140.467C160.537 140.004 160.222 139.592 159.777 139.232L154.107 134.642L155.235 133.248Z"
          fill="#2B2B2B"
        />
        <path
          d="M139.662 144.49L149.573 157.115L148.21 158.184L147.065 156.726L146.898 156.857C146.919 157.098 146.935 157.414 146.946 157.805C146.956 158.203 146.864 158.636 146.671 159.103C146.476 159.578 146.08 160.05 145.483 160.519C144.71 161.126 143.876 161.467 142.983 161.544C142.09 161.62 141.199 161.434 140.312 160.984C139.424 160.534 138.602 159.827 137.845 158.863C137.082 157.891 136.585 156.918 136.356 155.945C136.13 154.976 136.157 154.066 136.436 153.216C136.718 152.369 137.242 151.646 138.007 151.045C138.597 150.582 139.146 150.309 139.654 150.226C140.165 150.147 140.614 150.158 141.003 150.259C141.394 150.364 141.707 150.459 141.942 150.545L142.062 150.451L138.252 145.597L139.662 144.49ZM144.582 153.612C144.038 152.919 143.456 152.387 142.837 152.017C142.221 151.65 141.6 151.471 140.975 151.479C140.353 151.49 139.759 151.718 139.193 152.162C138.603 152.625 138.233 153.167 138.083 153.788C137.931 154.415 137.964 155.072 138.181 155.759C138.397 156.452 138.761 157.126 139.274 157.779C139.781 158.425 140.337 158.929 140.943 159.29C141.549 159.659 142.169 159.838 142.805 159.828C143.441 159.826 144.057 159.59 144.655 159.12C145.229 158.67 145.597 158.146 145.759 157.548C145.924 156.955 145.903 156.321 145.696 155.646C145.492 154.976 145.12 154.298 144.582 153.612Z"
          fill="#2B2B2B"
        />
        <path
          d="M127.997 157.581C128.651 157.235 129.309 157.044 129.972 157.008C130.638 156.976 131.252 157.112 131.814 157.416C132.379 157.725 132.839 158.215 133.195 158.886C133.508 159.477 133.646 160.018 133.607 160.508C133.571 161.003 133.415 161.459 133.137 161.876C132.859 162.293 132.512 162.677 132.096 163.029C131.679 163.388 131.247 163.725 130.801 164.042C130.217 164.455 129.741 164.784 129.373 165.031C129.003 165.284 128.755 165.51 128.631 165.708C128.502 165.908 128.505 166.136 128.64 166.391L128.669 166.445C129.02 167.107 129.474 167.526 130.031 167.701C130.584 167.878 131.225 167.774 131.954 167.387C132.711 166.986 133.216 166.506 133.471 165.947C133.725 165.388 133.84 164.875 133.815 164.406L135.604 164.146C135.667 164.915 135.568 165.593 135.305 166.179C135.04 166.773 134.678 167.28 134.219 167.701C133.758 168.129 133.267 168.48 132.748 168.755C132.417 168.931 132.015 169.092 131.543 169.239C131.068 169.393 130.565 169.456 130.034 169.428C129.499 169.402 128.967 169.216 128.44 168.871C127.913 168.525 127.433 167.943 126.998 167.123L123.398 160.328L124.983 159.488L125.723 160.885L125.803 160.842C125.792 160.561 125.844 160.227 125.96 159.839C126.075 159.451 126.294 159.057 126.615 158.657C126.937 158.258 127.397 157.899 127.997 157.581ZM128.51 159.133C127.883 159.465 127.42 159.868 127.121 160.342C126.817 160.818 126.658 161.309 126.645 161.815C126.628 162.323 126.733 162.792 126.961 163.222L127.729 164.672C127.754 164.556 127.862 164.404 128.055 164.216C128.246 164.034 128.476 163.841 128.745 163.635C129.012 163.436 129.275 163.245 129.534 163.062C129.79 162.886 129.998 162.742 130.156 162.629C130.539 162.357 130.878 162.066 131.172 161.756C131.464 161.452 131.657 161.123 131.751 160.77C131.842 160.423 131.781 160.048 131.568 159.645C131.276 159.095 130.852 158.786 130.295 158.72C129.736 158.661 129.141 158.798 128.51 159.133Z"
          fill="#2B2B2B"
        />
        <path
          d="M120.336 169.068L118.162 162.391L119.868 161.836L123.481 172.935L121.833 173.471L121.269 171.737L121.124 171.784C121.047 172.433 120.8 173.014 120.381 173.529C119.964 174.048 119.336 174.444 118.498 174.717C117.747 174.961 117.039 175.021 116.375 174.897C115.713 174.777 115.124 174.457 114.608 173.937C114.094 173.423 113.684 172.693 113.376 171.749L111.08 164.696L112.786 164.141L115.044 171.078C115.328 171.95 115.775 172.556 116.387 172.895C116.999 173.239 117.701 173.282 118.491 173.025C119.035 172.848 119.483 172.571 119.835 172.196C120.182 171.822 120.405 171.368 120.503 170.835C120.602 170.303 120.546 169.713 120.336 169.068Z"
          fill="#2B2B2B"
        />
        <path
          d="M105.702 161.335C106.003 161.292 106.275 161.279 106.518 161.295C106.76 161.307 106.93 161.329 107.026 161.361L106.799 162.99C106.352 162.941 105.965 162.956 105.638 163.033C105.311 163.111 105.039 163.301 104.821 163.603C104.598 163.901 104.42 164.359 104.288 164.977L104.086 165.927L110.02 176.931L108.094 177.206L103.588 168.454L103.468 168.472L101.595 178.136L99.6688 178.411L102.68 164.47C102.816 163.841 103.019 163.308 103.289 162.87C103.558 162.427 103.895 162.08 104.299 161.828C104.698 161.576 105.166 161.412 105.702 161.335Z"
          fill="#2B2B2B"
        />
        <path
          d="M87.3293 166.577C87.7032 166.604 88.0147 166.76 88.2635 167.047C88.5124 167.334 88.6236 167.664 88.5971 168.038C88.5706 168.412 88.414 168.724 88.1272 168.973C87.8403 169.221 87.51 169.333 87.136 169.306C86.762 169.28 86.4506 169.123 86.2017 168.836C85.9529 168.549 85.8417 168.219 85.8681 167.845C85.8857 167.597 85.965 167.374 86.106 167.176C86.2419 166.978 86.418 166.822 86.6341 166.711C86.8449 166.604 87.0766 166.559 87.3293 166.577Z"
          fill="#2B2B2B"
        />
        <path
          d="M74.878 164.938L76.7634 165.529L66.6553 178.671L64.7989 178.089L64.0006 161.528L65.886 162.119L66.4055 176.012L66.5215 176.048L74.878 164.938ZM72.3635 170.521L64.7638 168.139L65.2639 166.543L72.8635 168.925L72.3635 170.521Z"
          fill="#2B2B2B"
        />
        <path
          d="M60.908 160.01L53.4293 173.659L51.8565 172.798L54.6171 167.759L54.4839 167.686C54.271 167.801 53.9869 167.94 53.6315 168.103C53.2693 168.268 52.8353 168.354 52.3295 168.36C51.8168 168.368 51.2272 168.189 50.5607 167.824C49.6988 167.352 49.0571 166.72 48.6357 165.929C48.2142 165.138 48.0358 164.246 48.1004 163.253C48.1649 162.26 48.4918 161.226 49.0809 160.15C49.6749 159.066 50.3742 158.228 51.1788 157.634C51.9809 157.046 52.8278 156.712 53.7195 156.635C54.6088 156.562 55.4799 156.759 56.333 157.226C56.9906 157.587 57.4574 157.984 57.7336 158.418C58.0073 158.857 58.1741 159.275 58.234 159.672C58.2914 160.073 58.3268 160.398 58.3401 160.648L58.5267 160.751L59.3885 159.178L60.908 160.01ZM56.5573 164.282C56.9809 163.508 57.2413 162.764 57.3385 162.049C57.4332 161.339 57.3542 160.697 57.1014 160.126C56.8461 159.558 56.403 159.102 55.7721 158.756C55.1145 158.396 54.4709 158.268 53.8411 158.374C53.2045 158.481 52.6132 158.77 52.0673 159.239C51.5145 159.71 51.0384 160.31 50.6392 161.039C50.2448 161.759 50.0004 162.468 49.9061 163.167C49.8049 163.869 49.8839 164.51 50.1432 165.091C50.3956 165.674 50.8551 166.148 51.5215 166.514C52.1613 166.864 52.7878 166.997 53.4009 166.911C54.0116 166.829 54.5863 166.561 55.1249 166.105C55.6611 165.654 56.1386 165.046 56.5573 164.282Z"
          fill="#2B2B2B"
        />
        <path
          d="M45.7287 150.015C46.5456 150.681 47.104 151.459 47.4037 152.351C47.6996 153.239 47.7355 154.173 47.5114 155.154C47.2835 156.131 46.7886 157.087 46.0269 158.022C45.2588 158.965 44.4175 159.648 43.503 160.073C42.5845 160.494 41.6605 160.65 40.731 160.539C39.7975 160.426 38.9223 160.036 38.1054 159.37C37.2885 158.705 36.7321 157.928 36.4363 157.04C36.1365 156.148 36.1022 155.212 36.3334 154.231C36.5607 153.246 37.0583 152.282 37.8264 151.34C38.5881 150.405 39.4266 149.729 40.3419 149.311C41.2532 148.891 42.1756 148.737 43.1091 148.851C44.0386 148.961 44.9118 149.349 45.7287 150.015ZM44.711 151.264C44.0905 150.758 43.4503 150.501 42.7905 150.493C42.1306 150.485 41.4933 150.661 40.8784 151.023C40.2635 151.385 39.7096 151.868 39.2167 152.473C38.7239 153.078 38.3608 153.719 38.1276 154.399C37.8944 155.078 37.8469 155.742 37.9851 156.39C38.1233 157.039 38.5026 157.616 39.1231 158.121C39.7437 158.627 40.3855 158.882 41.0485 158.886C41.7115 158.891 42.3521 158.71 42.9702 158.345C43.5883 157.979 44.1438 157.494 44.6366 156.889C45.1295 156.284 45.4909 155.644 45.7209 154.969C45.9509 154.294 45.9952 153.634 45.8539 152.989C45.7125 152.345 45.3315 151.77 44.711 151.264Z"
          fill="#2B2B2B"
        />
        <path
          d="M30.6341 141.94L25.3943 146.43L24.2274 145.068L33.0912 137.473L34.2582 138.835L32.7578 140.12L32.8369 140.213C33.465 140.035 34.1247 140.03 34.816 140.198C35.5034 140.37 36.1339 140.79 36.7075 141.46C37.1822 142.014 37.4829 142.61 37.6098 143.248C37.7327 143.89 37.6535 144.545 37.372 145.213C37.0867 145.885 36.567 146.544 35.813 147.19L30.1808 152.016L29.0138 150.654L34.5537 145.907C35.2 145.353 35.5606 144.731 35.6355 144.039C35.707 143.344 35.5005 142.714 35.0159 142.148C34.7259 141.81 34.3443 141.54 33.8712 141.338C33.3948 141.132 32.8755 141.064 32.3133 141.132C31.7479 141.196 31.1881 141.465 30.6341 141.94Z"
          fill="#2B2B2B"
        />
        <path
          d="M18.2848 136.188L19.5642 135.368L22.8298 140.46L21.5505 141.28L18.2848 136.188ZM18.2446 141.306L17.2764 139.796L26.6415 133.79C27.0679 133.517 27.3481 133.25 27.482 132.989C27.6089 132.727 27.6457 132.475 27.5924 132.232C27.5321 131.988 27.4309 131.755 27.2886 131.533C27.182 131.367 27.0859 131.236 27.0005 131.14C26.9108 131.047 26.839 130.973 26.7852 130.917L27.9444 129.741C28.0484 129.818 28.1784 129.937 28.3344 130.095C28.4947 130.251 28.6651 130.47 28.8456 130.752C29.1191 131.178 29.2954 131.655 29.3746 132.182C29.4511 132.704 29.3878 133.221 29.1848 133.73C28.9791 134.235 28.5905 134.671 28.0191 135.037L18.2446 141.306Z"
          fill="#2B2B2B"
        />
        <path
          d="M12.3362 111.842C13.8944 111.326 15.3342 111.161 16.6556 111.348C17.977 111.535 19.1079 112.017 20.0484 112.794C20.989 113.571 21.6679 114.59 22.0852 115.85C22.5025 117.11 22.5658 118.332 22.2751 119.517C21.9844 120.702 21.3647 121.764 20.416 122.703C19.4674 123.641 18.2139 124.369 16.6557 124.885C15.0974 125.401 13.6576 125.565 12.3362 125.379C11.0149 125.192 9.88391 124.71 8.94338 123.932C8.00286 123.155 7.32395 122.137 6.90665 120.876C6.48935 119.616 6.42605 118.394 6.71674 117.209C7.00742 116.024 7.6271 114.962 8.57577 114.024C9.52444 113.085 10.7779 112.358 12.3362 111.842ZM12.9095 113.573C11.6302 113.997 10.6214 114.568 9.88303 115.288C9.14307 116.002 8.6673 116.798 8.45572 117.674C8.24254 118.545 8.28408 119.428 8.58033 120.322C8.87658 121.217 9.37099 121.952 10.0636 122.529C10.7546 123.101 11.611 123.455 12.6329 123.591C13.6532 123.723 14.803 123.577 16.0823 123.153C17.3616 122.73 18.3712 122.16 19.1112 121.446C19.8495 120.726 20.3253 119.931 20.5385 119.06C20.7501 118.184 20.7077 117.299 20.4115 116.404C20.1152 115.51 19.6216 114.776 18.9306 114.205C18.238 113.628 17.3816 113.274 16.3613 113.142C15.3394 113.006 14.1888 113.149 12.9095 113.573Z"
          fill="#2B2B2B"
        />
        <path
          d="M13.5589 98.9556L6.70373 99.7437L6.49888 97.962L18.0952 96.6288L18.3001 98.4105L16.3371 98.6362L16.351 98.757C16.9712 98.9611 17.5206 99.3263 17.9992 99.8526C18.4729 100.38 18.7601 101.081 18.8607 101.957C18.9441 102.681 18.8596 103.344 18.6073 103.944C18.35 104.545 17.9178 105.043 17.3105 105.439C16.6983 105.836 15.8989 106.091 14.9124 106.205L7.54394 107.052L7.33909 105.27L14.5868 104.437C15.4324 104.34 16.0796 104.026 16.5285 103.495C16.9769 102.959 17.1585 102.321 17.0735 101.581C17.0225 101.138 16.8575 100.7 16.5783 100.268C16.2986 99.8314 15.9068 99.4838 15.4028 99.2255C14.8983 98.9623 14.2836 98.8723 13.5589 98.9556Z"
          fill="#2B2B2B"
        />
        <path
          d="M18.0768 92.4937L6.4098 92.1278L6.46412 90.3959L8.22633 90.4512L8.23014 90.3297C7.65954 90.0989 7.2032 89.6994 6.86115 89.1311C6.51909 88.5628 6.35902 87.9292 6.38094 87.2304C6.38507 87.0988 6.39276 86.9343 6.40402 86.7369C6.41528 86.5396 6.42756 86.3905 6.44086 86.2895L8.26383 86.3467C8.24673 86.407 8.21958 86.5455 8.18236 86.7623C8.14025 86.9739 8.11546 87.1987 8.10799 87.4366C8.0902 88.0038 8.19332 88.5139 8.41734 88.967C8.63646 89.4148 8.94958 89.7744 9.35671 90.0457C9.75894 90.3117 10.2234 90.453 10.75 90.4695L18.133 90.7011L18.0768 92.4937Z"
          fill="#2B2B2B"
        />
        <path
          d="M12.6501 62.3005L12.1226 64.1099C11.571 64.0652 11.0592 64.1192 10.5872 64.2718C10.1167 64.4196 9.69239 64.6442 9.31429 64.9456C8.93274 65.2408 8.60723 65.5971 8.33776 66.0146C8.0683 66.4321 7.86125 66.8889 7.71663 67.385C7.45291 68.2897 7.4426 69.1759 7.68571 70.0436C7.93023 70.9064 8.43444 71.684 9.19833 72.3764C9.96364 73.0639 10.9932 73.5962 12.287 73.9733C13.5808 74.3505 14.7351 74.4548 15.7499 74.2862C16.7662 74.1128 17.6093 73.728 18.2792 73.1317C18.9505 72.5305 19.418 71.7776 19.6817 70.8729C19.8263 70.3768 19.8972 69.8803 19.8943 69.3834C19.8913 68.8865 19.8107 68.4118 19.6524 67.9593C19.4906 67.5005 19.251 67.0824 18.9336 66.7049C18.6127 66.3211 18.2101 66.0006 17.7258 65.7433L18.2532 63.9339C18.9771 64.2927 19.5882 64.74 20.0864 65.2757C20.5846 65.8115 20.9673 66.4085 21.2345 67.0669C21.4968 67.7238 21.6403 68.4174 21.6649 69.1475C21.6909 69.8728 21.5941 70.6124 21.3743 71.3663C21.0028 72.6406 20.3612 73.6832 19.4494 74.4939C18.5375 75.3047 17.4248 75.8273 16.111 76.0617C14.7972 76.2962 13.3524 76.1837 11.7765 75.7243C10.2007 75.2649 8.92173 74.5834 7.93975 73.6797C6.95777 72.776 6.30012 71.7373 5.96679 70.5636C5.63346 69.3899 5.65254 68.1659 6.02401 66.8916C6.24378 66.1377 6.55952 65.4619 6.97122 64.8642C7.38434 64.2617 7.88045 63.7546 8.45957 63.3429C9.03383 62.9298 9.67497 62.6312 10.383 62.4471C11.0862 62.2616 11.8419 62.2127 12.6501 62.3005Z"
          fill="#2B2B2B"
        />
        <path
          d="M26.8702 57.9491C26.3784 58.8811 25.7251 59.5818 24.9103 60.0512C24.0979 60.5161 23.1889 60.7353 22.1833 60.7088C21.18 60.6778 20.1451 60.3809 19.0787 59.8182C18.0033 59.2508 17.1674 58.5606 16.5711 57.7475C15.9771 56.93 15.6427 56.0547 15.5679 55.1217C15.4955 54.1841 15.7052 53.2493 16.197 52.3173C16.6887 51.3853 17.3408 50.6869 18.1533 50.2219C18.968 49.7525 19.8793 49.5345 20.8871 49.5679C21.8972 49.5968 22.9399 49.8949 24.0153 50.4623C25.0818 51.025 25.9097 51.714 26.4993 52.5291C27.0911 53.3398 27.4233 54.2139 27.4957 55.1515C27.5705 56.0845 27.362 57.0171 26.8702 57.9491ZM25.4453 57.1972C25.8189 56.4893 25.9447 55.811 25.823 55.1624C25.7012 54.5139 25.4025 53.9238 24.9269 53.3922C24.4512 52.8605 23.8684 52.4126 23.1784 52.0485C22.4883 51.6844 21.7874 51.455 21.0756 51.3601C20.3638 51.2652 19.7036 51.3493 19.0951 51.6125C18.4865 51.8756 17.9954 52.3612 17.6219 53.0692C17.2483 53.7771 17.1246 54.4566 17.2509 55.1075C17.3772 55.7584 17.6804 56.3509 18.1605 56.8849C18.6406 57.4189 19.2256 57.8679 19.9157 58.232C20.6057 58.5961 21.3044 58.8244 22.0117 58.917C22.719 59.0095 23.3747 58.923 23.9788 58.6575C24.5829 58.3919 25.0717 57.9052 25.4453 57.1972Z"
          fill="#2B2B2B"
        />
        <path
          d="M30.9314 50.0106L22.0619 42.4222L23.1883 41.1057L24.5742 42.2913L24.673 42.1759C24.3576 41.586 24.2452 40.9731 24.3358 40.3372C24.4226 39.698 24.713 39.0897 25.2071 38.5123C25.7077 37.9271 26.2571 37.5538 26.8554 37.3922C27.4531 37.2235 28.0826 37.232 28.7439 37.4177L28.8229 37.3253C28.5591 36.7063 28.4868 36.0543 28.606 35.3695C28.7213 34.6814 29.0507 34.0198 29.5941 33.3846C30.2726 32.5916 31.0759 32.1553 32.0039 32.0759C32.9281 31.9932 33.9119 32.3981 34.9551 33.2907L40.8912 38.3694L39.7253 39.7321L33.7892 34.6534C33.1348 34.0935 32.5139 33.8724 31.9265 33.99C31.3392 34.1075 30.8381 34.4089 30.4231 34.8939C29.8895 35.5175 29.6648 36.1621 29.7489 36.8274C29.8292 37.4895 30.1562 38.066 30.7298 38.5567L36.5042 43.4971L35.3185 44.8829L29.2438 39.6857C28.7395 39.2542 28.1934 39.0703 27.6055 39.1341C27.0137 39.1945 26.4971 39.4827 26.0558 39.9985C25.7528 40.3527 25.5638 40.7644 25.489 41.2338C25.4174 41.6993 25.4684 42.1696 25.6419 42.6448C25.8149 43.1128 26.1189 43.5329 26.5539 43.9051L32.0974 48.6479L30.9314 50.0106Z"
          fill="#2B2B2B"
        />
        <path
          d="M46.8051 39.1942L37.2417 26.3045L38.6332 25.2721L39.738 26.7613L39.9089 26.6345C39.894 26.3932 39.8865 26.077 39.8865 25.6859C39.8875 25.2877 39.9911 24.8576 40.1971 24.3956C40.4043 23.9265 40.813 23.4656 41.4233 23.0128C42.2126 22.4271 43.0548 22.1083 43.9498 22.0562C44.8447 22.004 45.7298 22.2148 46.6048 22.6885C47.4798 23.1622 48.2826 23.8913 49.0132 24.876C49.7497 25.8687 50.2193 26.8545 50.422 27.8334C50.6216 28.8082 50.5703 29.7168 50.2681 30.5593C49.9628 31.3977 49.4195 32.1067 48.6383 32.6863C48.0362 33.1331 47.4801 33.3912 46.97 33.4605C46.457 33.5257 46.0077 33.5026 45.6222 33.3912C45.2337 33.2757 44.9232 33.1717 44.6906 33.0793L44.5686 33.1699L48.2454 38.1256L46.8051 39.1942ZM42.1352 29.9412C42.6605 30.6491 43.2276 31.1967 43.8366 31.5839C44.4426 31.967 45.0581 32.1632 45.6833 32.1725C46.3054 32.1778 46.9053 31.9661 47.4831 31.5374C48.0852 31.0906 48.47 30.5591 48.6373 29.9429C48.8057 29.3196 48.7909 28.6619 48.5928 27.9698C48.3958 27.2707 48.0498 26.5875 47.5547 25.9202C47.0657 25.2611 46.5232 24.7425 45.9274 24.3645C45.3325 23.9794 44.717 23.7832 44.0807 23.7758C43.4454 23.7614 42.8227 23.9806 42.2123 24.4334C41.6265 24.8681 41.2443 25.3819 41.066 25.9747C40.8846 26.5635 40.8884 27.1978 41.0774 27.8777C41.2634 28.5535 41.616 29.2413 42.1352 29.9412Z"
          fill="#2B2B2B"
        />
        <path
          d="M58.8246 26.4254C58.1617 26.7536 57.4983 26.9267 56.8344 26.9445C56.1682 26.9578 55.5582 26.8048 55.0043 26.4855C54.4482 26.1616 54.0015 25.6591 53.6642 24.9781C53.3675 24.3788 53.2449 23.8346 53.2967 23.3454C53.3461 22.8516 53.5155 22.4003 53.8049 21.9913C54.0942 21.5823 54.4513 21.2076 54.8763 20.8671C55.3036 20.5198 55.7444 20.1941 56.1987 19.89C56.7939 19.4935 57.2788 19.177 57.6536 18.9406C58.0306 18.6973 58.2841 18.4785 58.414 18.2841C58.5484 18.0875 58.5516 17.8598 58.4234 17.6011L58.3964 17.5466C58.0637 16.8746 57.6213 16.4436 57.0692 16.2534C56.5216 16.061 55.8778 16.148 55.1378 16.5145C54.3705 16.8944 53.8521 17.3603 53.5826 17.9121C53.3132 18.4639 53.1843 18.9744 53.1961 19.4434L51.4008 19.654C51.3585 18.8835 51.4766 18.2088 51.7553 17.6298C52.0362 17.0441 52.412 16.5471 52.8825 16.1388C53.3554 15.7237 53.8552 15.3858 54.3818 15.125C54.7178 14.9586 55.1239 14.8083 55.6002 14.6742C56.0788 14.5333 56.5832 14.4843 57.1132 14.5271C57.6477 14.5677 58.1737 14.7679 58.6912 15.1279C59.2087 15.4879 59.6731 16.0833 60.0846 16.9141L63.4975 23.806L61.8903 24.6019L61.1888 23.1854L61.1071 23.2258C61.1106 23.5068 61.0492 23.8396 60.9231 24.2243C60.797 24.609 60.5679 24.9967 60.2356 25.3874C59.9033 25.7781 59.4329 26.1241 58.8246 26.4254ZM58.3548 24.8602C58.9904 24.5454 59.4643 24.1553 59.7765 23.6897C60.0932 23.222 60.265 22.7355 60.2919 22.2303C60.3233 21.7229 60.231 21.2513 60.0152 20.8154L59.2868 19.3445C59.2591 19.4599 59.1464 19.609 58.9486 19.7918C58.7531 19.9678 58.5178 20.1549 58.2429 20.3532C57.9703 20.5448 57.7022 20.7284 57.4387 20.9041C57.1775 21.073 56.9663 21.2116 56.805 21.3197C56.4143 21.581 56.0677 21.8629 55.7652 22.1654C55.465 22.461 55.2632 22.7842 55.1599 23.135C55.0589 23.479 55.1096 23.8553 55.3119 24.264C55.5884 24.8224 56.0041 25.1423 56.5589 25.2237C57.116 25.2984 57.7146 25.1772 58.3548 24.8602Z"
          fill="#2B2B2B"
        />
        <path
          d="M66.7966 15.1545L68.7855 21.8888L67.0655 22.3968L63.7592 11.2021L65.421 10.7113L65.9376 12.4605L66.0833 12.4175C66.1778 11.7715 66.4413 11.1971 66.8739 10.6943C67.3051 10.1866 67.9434 9.80789 68.7888 9.5582C69.5468 9.33434 70.2559 9.29394 70.9162 9.43701C71.5751 9.57522 72.155 9.91108 72.656 10.4446C73.1555 10.9733 73.5459 11.7137 73.8271 12.6661L75.9279 19.7794L74.2079 20.2874L72.1415 13.2907C71.8818 12.4112 71.4511 11.7936 70.8494 11.4377C70.2463 11.077 69.5464 11.0143 68.7495 11.2497C68.2005 11.4118 67.7449 11.6758 67.3828 12.0416C67.0255 12.406 66.7904 12.8531 66.6775 13.383C66.5646 13.9129 66.6043 14.5034 66.7966 15.1545Z"
          fill="#2B2B2B"
        />
        <path
          d="M81.202 23.2884C80.9001 23.3232 80.6279 23.3291 80.3856 23.3061C80.1438 23.2881 79.975 23.2617 79.8792 23.2268L80.1509 21.6043C80.5965 21.6651 80.9837 21.6612 81.3124 21.5927C81.6411 21.5241 81.9184 21.3417 82.1443 21.0453C82.3758 20.7534 82.5658 20.3006 82.7142 19.6868L82.9419 18.7425L77.3089 7.58109L79.2415 7.3581L83.5087 16.2292L83.6294 16.2152L85.7642 6.60548L87.6968 6.38248L84.3077 20.2373C84.1554 20.8617 83.9383 21.3891 83.6565 21.8194C83.3752 22.2548 83.0292 22.5931 82.6184 22.8342C82.2127 23.0749 81.7406 23.2262 81.202 23.2884Z"
          fill="#2B2B2B"
        />
        <circle cx="92" cy="92" r="39" fill="#2A2A2A" />
      </svg>
    </div>
  );
}
